
<template>
<div class="ol-dashboard__notif">
    <h4 class="ol-dashboard__container-title">Notifikasi</h4>
    <button v-on:click="viewData()" class="ol-btn ol-dashboard__address-add">Tandai sudah dibaca semua</button>
    
    <div v-if="laravelPagenation && !loading" class="ol-dashboard__notif-list" >
        <NotificationItem v-for="item in laravelPagenation.data"  :key="item.id" 
            :title="item.title" 
            :subtitle="item.text" 
            :created_at="item.created_at"
            :image_url="item.image"
            :detail_url="item.link ? item.link :'#'" 
            :read_status="item.status_read"
        />
    </div>
    <div v-else class="ol-dashboard__notif-list" >
        <NotifLoader v-if="loading" :qty="4" />
    </div>
    <div class="ol-paging" v-if="laravelPagenation">
        <pagination :data="laravelPagenation" @pagination-change-page="getData" :limit="2"></pagination>
    </div>
</div>
</template>
<script>
import NotificationItem from '@/components/notification/NotificationItem.vue'

import globalUrl from '@/util/globallUrl'
import NotifLoader from '@/components/loading/NotifLoader'
import {mapActions,mapGetters} from "vuex";
export default {
    name: "Notification",
    components: {
        NotificationItem,
        NotifLoader
    },
    computed:{
		...mapGetters("notification", ["laravelPagenation"]),
    },
    data() {
        return {
            loading:false,
            per_page:4
        }
        
    },
    methods:{
         ...mapActions('notification', ['fetchData','fetchView']),
        async getData(page = 1){
            this.loading=true;
            let data={
                page : page,
                per_page:this.per_page
            };
            this.fetchData(data).then((ok) =>{
                this.loading = false;
            }).catch((error)=>{
                this.loading = false;
                // console.log(error);
            });
        },

        async viewData(id = 'all'){
            let data={
                id : id
            };
            if(id == 'all')
            this.loading = true;
            this.fetchView(data).then((success) => {
                if(id == 'all'){
                    this.getData();
                }
            }).catch((error) =>{

            });
        }
    }

}
</script>