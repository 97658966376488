<template>
    <div>
    <div v-for="i in qty" :key="i" class="ol-dashboard__notif-list-item">
            <div class="ol-cart-floating__list-item-prd">
                <a href="" class="ol-notif-floating__link">
                <figure class="ol-cart-floating__list-item-prd-figure">
                    <div style="width:100px;height:100px;">
                        <SkeletonLoader />
                    </div>
                </figure>
                <div class="ol-cart-floating__list-item-prd-desc">
                    <h3  style="width:300px;height:21px;">
                        <span style="width:300px;height:21px;"><SkeletonLoader /></span>
                    </h3>
                    <small style="width:600px;height:51px;"><span style="width:600px;height:10px;"><SkeletonLoader /></span></small>
                    
                </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name:"NotifLoader",
    props:['qty'],
    components:{
        SkeletonLoader
    },
}
</script>
<style>
  
</style>