<template>
<div v-if="read_status == 0" class="ol-dashboard__notif-list-item ol-cart-floating__list-item--new">
    <div class="ol-cart-floating__list-item-prd">
       <router-link :to="detail_url" class="ol-notif-floating__link">
        <figure class="ol-cart-floating__list-item-prd-figure">
            <img :srcset="image_url" alt="" title="">
        </figure>
        <div class="ol-cart-floating__list-item-prd-desc">
            <h3>
                {{title}}
            </h3>
            <small v-html="subtitle">{{subtitle}}</small>
            <span>{{created_at}}</span>
        </div>
       </router-link>
    </div>
</div>
<div v-else class="ol-dashboard__notif-list-item">
    <div class="ol-cart-floating__list-item-prd">
        
        <router-link :to="detail_url" class="ol-notif-floating__link">
        <figure class="ol-cart-floating__list-item-prd-figure">
            <img :srcset="image_url" alt="" title="">
        </figure>
        <div class="ol-cart-floating__list-item-prd-desc">
            <h3>
                {{title}}
            </h3>
            <small v-html="subtitle">{{subtitle}}</small>
            <span>{{created_at}}</span>
        </div>
         </router-link>
    </div>
</div>
</template>
<script>
export default {
    name:"NotificationItem",
    props:[
        "title",
        "subtitle",
        "created_at",
        "image_url",
        "detail_url",
        "read_status"
    ],
}
</script>